.age-gate-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(5, 15, 36, 0.96);
    z-index: 999999;

    .box-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 100%;
        padding: 0 1.25rem;

        .box {
            svg {
                display: block;
                width: 68px;
                height: 90px;
                margin: 0 auto 1.875rem;

                @screen lg {
                    width: 82px;
                    height: 109px;
                    margin: 0 auto 3.125rem;
                }
            }

            #h1 {
                font-size: 1.875rem;
                line-height: 2.5rem;
                color: #FFF;
                margin: 0 0 2.5rem;
                font-family:  Marcellus, sans-serif;

                @screen lg {
                    font-size: 2.875rem;
                    line-height: 3.5rem;
                    margin: 0 0 4.063rem;
                }

                em {
                    font-family: 'Newsreader', serif;
                    display: block;
                    font-size: 2.25rem;
                    line-height: 3rem;

                    @screen lg {
                        font-size: 3.125rem;
                        line-height: 3.5rem;
                    }
                }
            }

            .box-buttons {
                button {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 6px;
                    width: 105px;
                    border: 1px solid #BB934D;
                    background-color: transparent;
                    font-size: 0.813rem;
                    line-height: 1rem;
                    font-weight: 700;
                    letter-spacing: 0.1625rem;
                    text-transform: uppercase;
                    color: #BB934D;
                    padding: 0.85rem 0.625rem;
                    transition: all 0.2s linear;

                    &:hover {
                        border: 1px solid #BB934D;
                        background-color: #BB934D;
                        color: #FFF;
                    }

                    &#isOldEnough {
                        border: 1px solid #BB934D;
                        background-color: #BB934D;
                        color: #FFF;
                    }

                    &#notOldEnough {
                        border: 1px solid #2B4271;
                        color: #FFF;

                        &:hover {
                            border: 1px solid #2B4271;
                            background-color: #2B4271;
                            color: #FFF;
                        }
                    }
                }
            }
        }
    }
}

body.blurred {
    overflow: hidden;
}

body.blurred > *:not(.age-gate-page) {
    filter: blur(6px);
}