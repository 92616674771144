body {
    @apply antialiased font-text text-base bg-primary-white dark:bg-primary-blue text-primary-blue dark:text-primary-white;
    box-sizing: border-box;
}

.container {
	@apply relative w-full !max-w-full px-6 md:px-0;

    @media screen and (min-width: 768px)  and (max-width: 1604px) {
        width: 100% !important;
        max-width: 100% !important;
        overflow-x: clip;
    }

    @media only screen and (min-width: 1605px) {
        width: calc(100% - 164px) !important;
        max-width: 1440px !important;
    }

    &.container-sticky {
        @media screen and (min-width: 768px)  and (max-width: 1604px) {
            max-width: 100% !important;
        }

        @media only screen and (min-width: 1604px) {
            max-width: 1440px !important;
        }
    }
}

.row {
    @apply flex -mx-3;
}

.column {
    @apply flex-none px-3;
}

.sticky-content  {
    @media screen and (min-width: 1024px)  {
        width: calc(100% - 89px);
    }

    &.producer-pad-exception{ 
        /* removes the right margin on producer when not large screen */
        @media only screen  and (min-width: 900px) and (max-width: 1604px) {
            width: 100%;
        }

        @media only screen and (min-width: 1604px) and (max-width: 1616px) {
            width: 1524px;
        }
    }

    /*@media only screen and (min-width: 1605px) {*/
    /*    width: calc(100% - 82px);*/
    /*}*/

    .sidebar-menu-button {
        &.inactive {
            @apply opacity-0 invisible;
        }
    }

    .sidebar-menu-content {
        &.active {
            @apply md:w-[236px] md:translate-x-0 md:opacity-100 md:visible z-999 md:bg-primary-white dark:bg-primary-blue;
        }
    }

    .container-sticky {
        @apply !w-full;
    }

    section,
    .tabs-wrap {
        @apply screen-down:!border-r-0;
    }
}

.modal-scrollbar {
    @apply overflow-x-hidden overflow-y-scroll pr-2;

    &::-webkit-scrollbar {
        @apply w-2;
    }

    &::-webkit-scrollbar-track {
        @apply bg-transparent;
    }

    &::-webkit-scrollbar-thumb {
        @apply bg-[#BFBFBF] rounded;
    }
}