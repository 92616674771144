.btn {
    @apply inline-flex items-center justify-center text-center font-text text-xs md:text-13 leading-4 font-bold uppercase tracking-[0.1625rem] px-6 py-3 md:px-14 md:py-6 border border-solid border-transparent transition-all ease-linear duration-200;

    &.btn-primary {
        @apply bg-primary-blue border-secondary-light-blue text-primary-white hover:bg-secondary-light-blue hover:border-secondary-light-blue hover:text-primary-white active:bg-primary-blue active:border-primary-blue active:text-primary-gold focus:bg-primary-blue focus:border-primary-blue focus:text-primary-gold disabled:bg-transparent disabled:border-transparent disabled:text-secondary-beige dark:bg-primary-gold dark:border-primary-gold dark:text-primary-blue dark:hover:bg-primary-gold-deep dark:hover:border-primary-gold-deep dark:active:bg-secondary-light-blue dark:active:border-secondary-light-blue dark:active:text-primary-white dark:focus:bg-secondary-light-blue dark:focus:border-secondary-light-blue dark:focus:text-primary-white;

        span.material-symbols-outlined {
            @apply text-primary-gold dark:text-primary-blue;
        }

        &:hover span.material-symbols-outlined {
            @apply text-primary-white dark:text-primary-blue;
        }

        &:active span.material-symbols-outlined,
        &:focus span.material-symbols-outlined {
            @apply text-primary-gold dark:text-primary-white;
        }

        &:disabled span.material-symbols-outlined {
            @apply text-secondary-beige;
        }
    }

    &.btn-secondary {
        @apply bg-transparent border-secondary-sand text-primary-blue hover:bg-primary-white hover:border-primary-gold-mid hover:text-primary-gold-deep active:bg-primary-gold-deep active:border-primary-gold-deep active:text-primary-white focus:bg-primary-gold-deep focus:border-primary-gold-deep focus:text-primary-white disabled:bg-transparent disabled:border-secondary-beige disabled:text-secondary-beige dark:bg-transparent dark:border-secondary-light-blue dark:text-primary-white dark:hover:bg-secondary-light-blue dark:hover:border-secondary-light-blue dark:active:bg-secondary-light-blue dark:active:border-secondary-light-blue dark:active:text-primary-white dark:focus:bg-secondary-light-blue dark:focus:border-secondary-light-blue dark:focus:text-primary-white;

        span.material-symbols-outlined {
            @apply text-primary-gold-deep dark:text-primary-gold;
        }

        &:hover span.material-symbols-outlined {
            @apply text-primary-gold-mid dark:text-primary-white;
        }

        &:active span.material-symbols-outlined,
        &:focus span.material-symbols-outlined {
            @apply text-primary-white dark:text-primary-white;
        }

        &:disabled span.material-symbols-outlined {
            @apply text-secondary-beige;
        }
    }

    &.btn-spirits-primary {
        @apply bg-primary-gold border-primary-gold text-primary-blue hover:bg-primary-gold-deep hover:border-primary-gold-deep hover:text-primary-white active:bg-secondary-light-blue active:border-secondary-light-blue active:text-primary-white focus:bg-secondary-light-blue focus:border-secondary-light-blue focus:text-primary-white disabled:bg-transparent disabled:border-secondary-beige disabled:text-secondary-beige dark:bg-primary-gold dark:border-primary-gold dark:text-primary-blue dark:hover:bg-primary-gold-deep dark:hover:border-primary-gold-deep dark:active:bg-secondary-light-blue dark:active:border-secondary-light-blue dark:active:text-primary-white dark:focus:bg-secondary-light-blue dark:focus:border-secondary-light-blue dark:focus:text-primary-white;
    }

    &.btn-spirits-secondary {
        @apply bg-primary-blue border-secondary-light-blue text-primary-white hover:bg-primary-white hover:border-primary-gold hover:text-primary-gold active:bg-secondary-light-blue active:border-secondary-light-blue active:text-primary-white focus:bg-secondary-light-blue focus:border-secondary-light-blue focus:text-primary-white disabled:bg-transparent disabled:border-transparent disabled:text-secondary-beige;

        span.material-symbols-outlined {
            @apply text-primary-gold;
        }

        &:hover span.material-symbols-outlined {
            @apply text-primary-gold;
        }

        &:active span.material-symbols-outlined,
        &:focus span.material-symbols-outlined {
            @apply text-primary-white;
        }

        &:disabled span.material-symbols-outlined {
            @apply text-secondary-beige;
        }
    }

    &.btn-promos-primary {
        @apply py-4 md:py-7 bg-primary-red border-primary-red text-primary-white hover:bg-[#991528] border-[#991528] active:bg-[#610713] active:border-[#610713] focus:bg-[#610713] focus:border-[#610713] disabled:bg-transparent disabled:border-transparent disabled:text-secondary-beige;

        span.material-symbols-outlined {
            @apply text-primary-gold;
        }

        &:hover span.material-symbols-outlined {
            @apply text-primary-gold;
        }

        &:active span.material-symbols-outlined,
        &:focus span.material-symbols-outlined {
            @apply text-primary-white;
        }

        &:disabled span.material-symbols-outlined {
            @apply text-secondary-beige;
        }
    }

    &.btn-outline {
        @apply border-secondary-sand bg-transparent text-primary-blue hover:bg-[#F0EDE3] active:bg-[#E5DFC7] focus:bg-[#E5DFC7] dark:border-secondary-light-blue dark:text-secondary-light-blue dark:hover:bg-[#0F1E3E] dark:hover:text-primary-white dark:active:bg-[#07142F] dark:focus:bg-[#07142F] disabled:hover:bg-transparent disabled:border-[#E1DED1] disabled:text-[#B2B4B9] ;
    }
}

.btn span.material-symbols-outlined {
    @apply inline-block text-xl pr-1.5 transition-all ease-linear duration-200;
}