.block-who_we_are {
    @apply overflow-hidden;
    
    .entry {
        p {
            @apply font-title text-base !leading-[1.5625rem] md:text-xl md:!leading-[1.875rem] lg:text-22 lg:!leading-8 font-normal;
        }
    }
}

.block-wine_spirits {
    .entry {
        p {
            @apply font-title text-lg lg:text-22 lg:!leading-8 font-normal text-primary-white;
        }
    }

    .svg-text {
        @media only screen and (min-width: 1680px) {
            @apply w-[86%];
        }

        @media only screen and (min-width: 1921px) {
            @apply w-[81%];
        }

        @media only screen and (min-width: 2200px) {
            @apply w-[78%];
        }

        @media only screen and (min-width: 2380px) {
            @apply w-[76%];
        }

        @media only screen and (min-width: 2560px) {
            @apply w-[72%];
        }
    }
}

.blog-posts-home {
    .slideshow {
        @apply md:!w-full md:justify-between md:!will-change-auto;
    }

    .custom-blog-post-item {
        @apply md-down:pt-5 md-down:pb-1 md-down:px-6 md:!ml-0 md:!mr-0 mb-5 md:!w-[32%] md:last:!mr-0 !border !border-secondary-light-blue;

        .blog-post-item-inner {
            @apply md-down:pt-8 md-down:pb-6 md-down:min-h-[auto];
        }

        h2 {
            @apply md-down:text-10 md-down:leading-[0.8125rem] md-down:mb-[0.3rem];
        }

        h3 {
            @apply lg-down:text-lg text-primary-white;
        }
    }
}

.blog-posts-slider-cocktail {
    .custom-blog-post-item {
        @apply md:px-4 lg:px-6 md:!w-[23.5%];
    }
}

.blog-posts-slider-home-pager {
    @apply -ml-6;

    ul li button.active {
        @apply bg-[#4A659A];
    }
}

.home-cocktail-creations {
    img.img-top {
        @media only screen and (min-width: 1921px) {
            @apply !h-[765px];
        }

        @media only screen and (min-width: 2200px) {
            @apply !h-[840px];
        }

        @media only screen and (min-width: 2320px) {
            @apply !h-[1000px];
        }
    }

    img.img-bottom {
        @media only screen and (min-width: 1921px) {
            @apply !h-[800px];
        }

        @media only screen and (min-width: 2200px) {
            @apply !h-[880px];
        }

        @media only screen and (min-width: 2320px) {
            @apply !h-[1000px];
        }
    }

    .bgr-ellipse-shadow {
        @media only screen and (min-width: 2100px) {
            @apply w-[80%] h-[80%];
        }

        @media only screen and (min-width: 2560px) {
            @apply w-[70%] h-[70%];
        }
    }

    .blog-posts-home {
        .custom-blog-post-item {
            h2 {
                @apply text-primary-gold;
            }
        }
    }
}

.three-col-slider-section {
    @apply relative z-10 before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-[calc(100%+1000px)] before:bg-gradient-2 before:z-negative-1;
}

.three-col-slider-wrap {
    .three-col-slider {
        .slideshow {
            .box {
                @apply bg-white m-0 p-0;
            }

            h2 {
                @apply md-down:mb-1;
            }
        }
    }

    .three-col-slider-nav {
        .three-col-slider-nav-prev,
        .three-col-slider-nav-next {
            &.glide__arrow--disabled {
                @apply opacity-30 pointer-events-none;
            }
        }
    }

    .three-col-slider-pager {
        button {
            &.glide__bullet--active {
                @apply bg-opacity-100;
            }
        }
    }
}

.instagram-section {
    ul {
        li:nth-child(n+3) {
            @apply border-t-0;

            @screen sm {
                @apply border-t;
            }
        }

        li:nth-child(n+8) {
            @screen sm {
                @apply border-t-0;
            }
        }
    }
}