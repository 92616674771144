.blk.block-our_principles {
    @apply md:scroll-mt-[65px] lg:scroll-mt-[80px];
}

.blk.block-our_values {
    @apply -scroll-mt-[60px] md:-scroll-mt-[2px] lg:-scroll-mt-[40px];
}

.blk.block-our_story {
    @apply scroll-mt-[20px] md:scroll-mt-[108px] lg:scroll-mt-[145px] xl:scroll-mt-[115px];
}

.blk.block-our_history {
    @apply -scroll-mt-[50px] lg:-scroll-mt-[60px];
}

.blk.block-our_people_block {
    @apply -scroll-mt-[2px] lg:scroll-mt-[68px];
}

.about-parallax-images {
    .entry {
        p {
            @apply text-base lg:font-title lg:text-22 lg:!leading-8 font-normal mb-8;
        }
    }

    .btn.btn-primary {
        @apply md:py-5;
    }
}

.circle-slider-section {
    .circle-slider-wrap {
        .entry {
            p {
                @apply font-title text-21 leading-[1.75rem] md:text-lg lg:text-22 lg:!leading-8 font-normal text-primary-white md:text-ellipsis md:line-clamp-6 md:h-[168px] lg:h-[192px];
            }
        }
    }


    /*.circle-animation {*/
    /*    animation: circleAnimation 1s 1 linear;*/
    /*}*/

    /*.circle-animation-rev {*/
    /*    animation: circleAnimationRev 1s 1 linear;*/
    /*}*/


    /*.circle-animation-1 {*/
    /*    animation: circleAnimation-1 1s 1 linear ;*/
    /*}*/

    .circle-animation-1 {
        animation: circleAnimation-1 1s 1 linear;
    }

    .circle-animation--1 {
        animation: circleAnimation--1 1s 1 linear;
    }

    .circle-animation-2 {
        animation: circleAnimation-2 1s 1 linear ;
    }

    .circle-animation--2 {
        animation: circleAnimation--2 1s 1 linear;
    }

    .circle-animation-3 {
        animation: circleAnimation-3 1s 1 linear ;
    }

    .circle-animation--3 {
        animation: circleAnimation--3 1s 1 linear;
    }



    svg {
            .circle-border.active {
                stroke: #BB934D;
            }
        }
}

/*@-webkit-keyframes circleAnimation-1 {*/
/*    from {*/
/*        -webkit-transform: rotate(0deg);*/
/*    }*/

/*    to {*/
/*        -webkit-transform: rotate(120deg);*/
/*    }*/
/*}*/

@keyframes circleAnimation-1 {
    0% {
      rotate: -120deg;
    }
    100% {
      rotate: 0deg;
    }
}
@keyframes circleAnimation-2 {
    0% {
      rotate: -120deg;
    }
    100% {
      rotate: 0deg;
    }
}

@keyframes circleAnimation-3 {
    0% {
      rotate: -120deg;
    }
    100% {
      rotate: 0deg;
    }
}
@keyframes circleAnimation--1 {
    from {
        rotate:120deg;
    }

    to {
        rotate:0deg;
    }
}
@keyframes circleAnimation--3 {
    from {
        rotate:120deg;
    }

    to {
        rotate:0deg;
    }
}

@keyframes circleAnimation--2 {
    from {
        rotate:120deg;
    }

    to {
        rotate:0deg;
    }
}


.about-tabs {
    .about-tab-content {
        .about-tab-box {
            &:nth-child(1) {
                @apply md:before:block before:top-[10px] before:h-[calc(100%+5px)];
            }
        }

        .about-tab-box.active {
            .about-tab-box-title {
                span {
                    @apply bg-primary-white border-primary-gold;
                }
            }

            .about-tab-box-text {
                @apply md:block;
            }

            .about-tab-image-mobile {
                @apply block;
            }
        }
    }

    .about-tab-image.active {
        @apply md:block;
    }
}

.timeline-slider-section {
    .timeline-slider {
        .slideshow {
            .glide__slide--active {
                .timeline-dot {
                    @apply bg-primary-white border-primary-gold;
                }
            }
        }
    }

    .timeline-slider-wrap {
        .timeline-slider-controls {
            .timeline-slider-prev,
            .timeline-slider-next {
                &.glide__arrow--disabled {
                    @apply opacity-30 pointer-events-none;
                }
            }
        }
    }
}

.ellipse-slider-section {
    .ellipse-slider-wrap {
        img.img-before,
        img.img-after {
            @media only screen and (min-width: 320px) {
                height: 24px;
            }

            @media only screen and (min-width: 379px) {
                height: 32px;
            }

            @media only screen and (min-width: 479px) {
                height: 40px;
            }

            @media only screen and (min-width: 667px) {
                height: 60px;
            }
        }

        img.img-before {
            @apply lg:h-[72px] xl:h-[96px] 2xl:h-[145px];
        }

        img.img-after {
            @apply lg:h-[72px] xl:h-[96px] 2xl:h-[145px];
        }
    }

    .entry {
        p {
            @apply md:font-title text-base leading-[1.5625rem] lg:text-22 lg:!leading-8 font-normal;
        }
    }
}