@layer base {
    input:not([type='submit']),
    textarea,
    select {
        @apply box-border inline-block w-full h-14 leading-[56px] font-text text-base font-normal bg-primary-white dark:bg-primary-blue text-primary-blue dark:text-primary-white px-3.5 py-0 border border-solid border-secondary-sand/[.50] dark:border-secondary-light-blue/[.50] transition-all ease-linear duration-200 focus:ring-0 focus:outline-0 focus:shadow-none focus:border-secondary-sand dark:focus:border-secondary-light-blue;
    }

    textarea {
        @apply h-[137px] leading-[1.563rem] p-3;
    }

    select {
        @apply whitespace-nowrap overflow-hidden text-ellipsis appearance-none bg-primary-white dark:bg-primary-blue text-primary-blue dark:text-primary-white;
        background-image: url(../images/icon-select-dropdown.svg);
        background-repeat: no-repeat;
        background-size: 11px 7px;
        padding-left: 0.875rem;
        padding-right: 2.25rem;
    }

    .dark select {
        background-image: url("data:image/svg+xml,%3Csvg class='icon icon-tabler icon-tabler-chevron-down' fill='none' height='24' stroke='%23FAF8F5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h24v24H0z' fill='none' stroke='none'/%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
        background-size: 16px 16px;
    }

    select::-ms-expand {
        @apply opacity-100;
    }

    .tab-dropdown-button {
        &:before {
            background-image: url(../images/icon-select-dropdown.svg);
            background-repeat: no-repeat;
            background-size: 11px 7px;
            background-position: right 0.875rem center;
            padding-left: 0.875rem;
            padding-right: 2.25rem;
        }
    }

    .dark {
        .tab-dropdown-button {
            &:before {
                background-image: url("data:image/svg+xml,%3Csvg class='icon icon-tabler icon-tabler-chevron-down' fill='none' height='24' stroke='%23FAF8F5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h24v24H0z' fill='none' stroke='none'/%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
                background-size: 16px 16px;
            }
        }
    }

    input::placeholder,
    textarea::placeholder {
        @apply opacity-100;
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
        @apply text-primary-blue dark:text-primary-white;
    }

    input:-moz-placeholder,
    textarea:-moz-placeholder {
        @apply text-primary-blue dark:text-primary-white;
    }

    input::-moz-placeholder,
    textarea::-moz-placeholder {
        @apply text-primary-blue dark:text-primary-white;
    }

    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
        @apply text-primary-blue dark:text-primary-white;
    }

    input[type="search"]::-ms-clear,
    input[type="search"]::-ms-reveal {
        @apply hidden w-0 h-0;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        @apply hidden;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    input.input-primary-text,
    textarea.input-primary-text {
        @apply text-primary-blue dark:text-primary-white;
    }

    input.input-primary-text::-webkit-input-placeholder,
    textarea.input-primary-text::-webkit-input-placeholder {
        @apply text-primary-blue dark:text-primary-white;
    }

    input.input-primary-text:-moz-placeholder,
    textarea.input-primary-text:-moz-placeholder {
        @apply text-primary-blue dark:text-primary-white;
    }

    input.input-primary-text::-moz-placeholder,
    textarea.input-primary-text::-moz-placeholder {
        @apply text-primary-blue dark:text-primary-white;
    }

    input.input-primary-text:-ms-input-placeholder,
    textarea.input-primary-text:-ms-input-placeholder {
        @apply text-primary-blue dark:text-primary-white;
    }
}

.form-field-search {
    input:not([type='submit']) {
        @apply bg-white dark:bg-transparent text-primary-blue dark:text-primary-white pl-11;
    }

    input:not([type='submit'])::-webkit-input-placeholder {
        @apply text-primary-blue dark:text-primary-white;
    }

    input:not([type='submit']):-moz-placeholder {
        @apply text-secondary-sand dark:text-primary-white;
    }

    input:not([type='submit'])::-moz-placeholder {
        @apply text-secondary-sand dark:text-primary-white;
    }

    input:not([type='submit']):-ms-input-placeholder {
        @apply text-secondary-sand dark:text-primary-white;
    }
}

.dark .form-field-select {
    select {
        background-image: url("data:image/svg+xml,%3Csvg class='icon icon-tabler icon-tabler-chevron-down' fill='none' height='24' stroke='%23FAF8F5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h24v24H0z' fill='none' stroke='none'/%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
        background-size: 16px 16px;
    }
}

.form-field-label {
    label {
        @apply text-base !leading-[1.438rem] font-bold normal-case tracking-zero text-primary-blue dark:text-primary-white mb-[0.5rem];
    }
}

.form-field-label-normal {
    label,
    legend {
        @apply text-base !leading-[1.438rem] font-bold normal-case tracking-zero text-primary-blue dark:text-primary-white mb-[0.5rem];
    }
}

.step-datepicker,
.field-tel-input {
    input {
        @apply !bg-white dark:!bg-transparent;
    }
}

.field-tel-input {
    input.input-primary-text {
        @apply text-primary-blue dark:text-primary-blue;
    }
}

.form-field-radio {
    label {
        @apply relative z-1 block text-primary-blue dark:text-primary-white text-base !leading-[1.563rem] font-medium cursor-pointer pl-8;

        input {
            @apply !absolute !opacity-0 !w-0 !h-0;
        }

        .radiomark {
            @apply absolute top-0 left-0 w-6 h-6 bg-transparent border-2 border-solid border-secondary-sand dark:border-secondary-light-blue rounded-full after:content-[''] after:w-3 after:h-3 after:absolute after:top-1/2 after:left-0 after:right-0 after:mx-auto after:transform after:-translate-y-1/2 after:bg-primary-gold after:rounded-full after:hidden;
        }

        input:checked ~ .radiomark {
            @apply border-primary-gold after:block;
        }
    }
}

.form-field-radio-custom {
    .form-field-radio {
        label {
            @apply text-base font-normal tracking-zero lowercase;

            .bg-radiomark {
                @apply !hidden;
            }
        }
    }

    span.horizontal {
        @apply !border-0 !border-none !w-auto;
    }
}

.form-field-checkbox {
    .checkmark {
        @apply block relative w-6 h-6 bg-transparent border border-solid border-secondary-sand dark:border-secondary-light-blue after:content-[''] after:absolute after:top-[0.25rem] after:left-[0.5rem] after:w-1.5 after:h-3 after:border-t-0 after:border-r-2 after:border-b-2 after:border-l-0 after:border-solid after:border-primary-white after:rotate-45 after:hidden;
    }

    input {
        @apply !absolute !opacity-0 !w-0 !h-0;
    }

    input:checked ~ .checkmark {
        @apply border-primary-gold bg-primary-gold;
    }

    input:checked ~ .checkmark:after {
        @apply block;
    }
}

.custom-checkbox {
    label {
        @apply block w-full relative cursor-pointer text-11 leading-none font-bold uppercase tracking-[0.10313rem] text-primary-blue dark:text-primary-white pl-9 cursor-pointer;

        input {
            @apply absolute opacity-0 w-0 h-0;
        }

        .checkmark {
            @apply absolute -top-2 left-0 w-6 h-6 bg-transparent border border-solid border-secondary-sand/[.50] after:content-[''] after:absolute after:top-1 after:left-2 after:w-1.5 after:h-3 after:border-t-0 after:border-r-2 after:border-b-2 after:border-l-0 after:border-solid after:border-primary-white after:rotate-45;
        }

        input:checked ~ .checkmark {
            @apply bg-primary-gold border border-solid border-primary-gold;
        }
    }
}

.no-error-icon {
    .bg-alert-error svg {
        @apply hidden;
    }
}

.errorlist li{
    @apply text-white bg-primary-red p-2 mb-2 font-semibold;
}