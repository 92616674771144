.tabs-wrap {
    #select-tab {
        li a {
            &.active {
                @apply font-bold border-secondary-sand border-b-primary-gold md:border-b-secondary-sand dark:border-secondary-light-blue bg-primary-white text-primary-blue dark:bg-primary-blue dark:text-primary-gold md-down:!bg-white md-down:!border md-down:!border-t-0 md-down:!border-secondary-sand md-down:!text-primary-blue md-down:!font-normal dark:md-down:!bg-primary-blue dark:md-down:!border-secondary-light-blue dark:md-down:!text-primary-white;
            }
        }
    }

    .tab-box {
        @apply hidden;

        &.active {
            @apply block;
        }

        .tabs-slider {
            .slideshow {
                img {
                    @apply w-full block;
                }
            }
        }
    }

    /*.tabs-wrap-overflow {
        @media only screen and (max-width: 767px) {
            @apply overflow-x-auto;
        }
    }

    .tabs-wrap-mobile {
        @media only screen and (max-width: 767px) {
            @apply w-[767px];
        }
    }*/

    .tab-custom-dropdown {
        &.active {
            @apply md-down:block;
        }
    }

    .tabs-content {
        .tabs-content-inner {
            .tabs-content-box:last-child {
                @apply !border-b-0;
            }
        }
    }

    .content {
        p:last-child {
            @apply m-0;
        }

        p:first-child {
            @apply mb-4;
        }
    }

    .tabs-content-inner {
        #blk1-1 + #blk2-1 {
            @screen md-down {
                @apply pb-0;
            }
        }
    }

    .tabs-content-box + .tabs-content-box h3.h2-title {
        @screen md-down {
            @apply -mt-2.5;
        }
    }
}

.view-buttons {
    button {
        &.active {
            @apply bg-primary-gold;
        }

        &.active span {
            @apply text-secondary-beige dark:text-primary-blue;
        }
    }
}

.half-slider-wrap {
    .half-slider-controls {
        .half-slider-prev,
        .half-slider-next {
            &.glide__arrow--disabled {
                @apply opacity-20 pointer-events-none;
            }
        }
    }
}

.list-view {
    .grid-view-content {
        @apply hidden;
    }
}

.grid-view {
    .grid-view-title {
        @apply block;
    }
    
    .list-view-content {
        @apply hidden;
    }
}

.pagination-custom {
    a {
        &.current {
            @apply border-primary-blue bg-primary-blue text-primary-white dark:border-secondary-light-blue dark:bg-secondary-light-blue;
        }
    }
}

.custom-blog-posts {
    .custom-blog-post-item {
        &:nth-child(-n+3) {
            @apply lg:border-t;
        }

        &:nth-child(-n+2) {
            @apply md:border-t;
        }
    }
}

.grid-items {
    .grid-item {
        &:nth-child(-n+4) {
            @apply lg:border-t;
        }

        &:nth-child(-n+3) {
            @apply md:border-t;
        }
    }
}

#blog-filter {
    a {
        &.active {
            @apply bg-primary-gold pl-11;

            span {
                @apply opacity-100;
            }
        }
    }
}

.sm-accordion {
    & > button span:before {
        @apply content-['\e5cf'];
    }

    &.active > button span:before {
        @apply content-['\e5ce'];
    }

    .sm-accordion-content {
        @apply hidden;
    }

    &.active {
        .sm-accordion-content {
            @apply block;
        }
    }
}

.checkbox-custom {
    input:checked ~ span {
        @apply bg-primary-gold border-primary-gold after:block;
    }

    span {
        @apply after:hidden after:content-[''] after:absolute after:top-0.5 after:left-[7px] after:w-[5px] after:h-[11px] after:border-0 after:border-b-2 after:border-r-2 after:border-solid after:border-primary-white after:transform after:rotate-45;
    }
}

.swiper-button-disabled {
    @apply opacity-50;
}

.main-content {
    .sticky-content {
        .list-only-section:last-child {
            @apply !border-b-0;
        }
    }
}

.ffrc-wrap {
    .form-field-radio-custom {
        span.horizontal {
            @apply !w-[100px] md:!w-[160px] !p-0;
        }

        .form-field-radio-step {
            @apply justify-start;

            label {
                @apply justify-start py-0 px-0 sm:py-6;
            }
        }
    }
}

.custom-checkbox-links {
    .custom-checkbox-link {
        @apply inline-flex justify-center items-center relative cursor-pointer text-10 leading-none tracking-[0.125rem] uppercase text-primary-white px-[1.69rem] py-3 bg-transparent border border-solid border-secondary-light-blue rounded-3xl text-center transition-all ease-linear duration-200 lg:hover:bg-secondary-sand lg:hover:text-primary-white;

        &.active {
            @apply bg-primary-gold text-primary-white;
        }
    }
}

.custom-checkbox-buttons {
    .custom-checkbox-button {
        label {
            @apply inline-flex justify-center items-center relative cursor-pointer text-10 leading-none tracking-[0.125rem] uppercase text-primary-white px-[1.69rem] py-3 bg-transparent border border-solid border-secondary-light-blue rounded-3xl text-center transition-all ease-linear duration-200 lg:hover:bg-secondary-sand lg:hover:text-primary-white;
        }

        input {
            @apply absolute opacity-0 w-0 h-0;
        }

        span {
            @apply opacity-0;
        }

        input:checked + label {
            @apply bg-primary-gold;
        }

        input:checked + label span {
            @apply opacity-100;
        }
    }

    &.secondary {
        .custom-checkbox-button {
            label {
                @apply text-primary-white dark:text-primary-white;
            }

            input:checked + label {
                @apply text-primary-white;
            }

            input:checked + label span {
                @apply text-primary-white;
            }
        }
    }
}

.title-white {
    h1,
    h2 {
        @apply text-primary-white;
    }
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar { 
        display: none;
    }
}

.position-initial {
    position: initial;
}

.last-hr {
    hr:last-child {
        @apply hidden;
    }
}

.menu-holder {
    .extended {
        @apply opacity-0;
    }

    .menu {
        @apply xl:pl-[15px];
    }

    .menu.sticky {
        @apply w-[82px];
    }
}

.menu-open {
    &.menu-holder {
        @apply !w-[264px];
        overflow: initial;
    }

    .extended {
        @apply inline-block opacity-100;
    }

    .sidebar-menu-button {
        @apply transform rotate-180;
    }

    .salestool-menu {
        a {
            @apply hover:bg-[#F0EDE3] active:bg-[#E5DFC7] focus:bg-[#E5DFC7] dark:hover:bg-[#0F1E3E] dark:hover:text-primary-white dark:active:bg-[#07142F] dark:focus:bg-[#07142F];
        }
    }

    .menu.sticky {
        @apply w-[248px];
    }
}

.custom-js-popup-overlay {
    &.active {
        @apply block;
    }
}

.custom-js-popup {
    &.active {
        @apply block;
        overflow: hidden auto;
    }
}

.select-input-combined {
    select {
        @apply !bg-white dark:!bg-primary-blue !border-r-0 !text-primary-blue dark:!text-primary-white;
        background-image: url("data:image/svg+xml,%3Csvg class='icon icon-tabler icon-tabler-chevron-down' fill='none' height='24' stroke='%23050F24' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h24v24H0z' fill='none' stroke='none'/%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
        background-size: 20px 20px;
        background-position: right 0 center;
        padding-right: 0.75rem;
    }

    input {
        @apply !bg-white dark:!bg-primary-blue !border-l-0 !text-primary-blue dark:!text-primary-white;
    }

    input {
        @apply !text-primary-blue dark:!text-primary-white;
    }

    input::-webkit-input-placeholder {
        @apply !text-primary-blue dark:!text-primary-white;
    }

    input:-moz-placeholder {
        @apply !text-primary-blue dark:!text-primary-white;
    }

    input::-moz-placeholder {
        @apply !text-primary-blue dark:!text-primary-white;
    }

    input:-ms-input-placeholder {
        @apply !text-primary-blue dark:!text-primary-white;
    }
}

.dark .select-input-combined select {
    background-image: url("data:image/svg+xml,%3Csvg class='icon icon-tabler icon-tabler-chevron-down' fill='none' height='24' stroke='%23FAF8F5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h24v24H0z' fill='none' stroke='none'/%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
}

.map-top-ellipse:before {
    clip-path: ellipse(50% 14% at 50% 1%);

    @screen md {
        clip-path: ellipse(50% 33% at 50% 1%);
    }
}

.map-bottom-ellipse:after {
    clip-path: ellipse(50% 14% at 50% 100%);

    @screen md {
        clip-path: ellipse(50% 33% at 50% 100%);
    }
}

.expired-info {
    &:hover .expired-info-icon {
        @media only screen and (max-width: 1279px) {
            @apply pr-1;
        }
    }

    &:hover .expired-info-text {
        @media only screen and (max-width: 1279px) {
            @apply block;
        }
    }
}

.srt-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important; 
}

[x-cloak] { 
    display: none !important; 
}

#grid-list-section + #estate-tabs {
    @apply md:border-t md:border-solid md:border-secondary-sand dark:border-secondary-light-blue;
}

#intro + #grid-list-section + #estate-tabs {
    @apply md:border-t-0;
}

.hero-section {
    .hero-section-title {
        @media (min-width: 1024px) and (max-width: 1440px) and (max-height: 801px) {
            @apply text-[3.125rem] leading-[3.75rem];
        }   
    }

    .hero-section-logo {
        @media (min-width: 1024px) and (max-width: 1440px) and (max-height: 801px) {
            @apply w-[75px] lg:h-[100px];
        }  
    }
}

.steps .step-items li.is-completed {
    @apply border-secondary-beige dark:border-secondary-light-blue dark:bg-primary-blue;
}

.steps .step-items li.is-completed .step-num {
    @apply hidden;
}

.steps .step-items li.is-completed .step-title {
    @apply text-secondary-beige dark:text-secondary-light-blue;
}

.steps .step-items li.is-completed .step-check-icon {
    @apply block;
}

.table-expired {
    @apply text-[#B42318];

    a {
        @apply text-[#B42318];
    }
}

.rhb-steps.rhb-steps-draggable {
    section:last-child .container > div {
        @apply border-b-0;
    }
}