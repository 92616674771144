.btn-menu-wrap .btn-menu {
    @apply relative w-[1.375rem] h-4 z-30;
}

.btn-menu-wrap .btn-menu:before,
.btn-menu-wrap .btn-menu:after,
.btn-menu-wrap .btn-menu span {
    @apply bg-primary-white absolute left-0 right-0 h-px;
    top: 6px;
}

.btn-menu-wrap .btn-menu:before,
.btn-menu-wrap .btn-menu:after {
    @apply content-[''] top-0;
}

.btn-menu-wrap .btn-menu span {
    @apply left-0 w-full;
}

.btn-menu-wrap .btn-menu:after {
    @apply top-3;
}

.btn-menu-wrap.active .btn-menu span {
    @apply opacity-0;
}

.btn-menu-wrap.active .btn-menu:after,
.btn-menu-wrap.active .btn-menu:before {
    @apply top-1.5 left-0 right-0 bg-primary-white transform rotate-[45deg];
}

.btn-menu-wrap.active .btn-menu:after {
    @apply transform rotate-[-45deg];
}

.navigation-menu {
    &.active {
        @media only screen and (max-width: 1024px) {
            @apply absolute block top-[83px] left-0 w-full z-999 bg-primary-white border-t border-solid border-secondary-sand dark:bg-primary-blue dark:border-secondary-light-blue;
        }
    }

    ul > li > a.active {
        @apply lg:bg-secondary-light-blue lg:hover:text-primary-white;
    }

    > ul > li:hover > a {
        @apply lg:text-primary-gold;
    }

    > ul > li:hover > a.active {
        @apply lg:text-primary-white;
    }

    li:hover .dropdown-menu {
        @apply lg:block;
    }

    .resource-cart-menu {
        li > a.active {
            @apply lg:bg-secondary-light-blue lg:hover:text-primary-white;
        }

        .cart-button {
            &.active {
                @apply bg-secondary-light-blue;
            }
        }
    }
}

.main-header {
    .header-search-form .header-search-form-inner {
        @apply transition-all ease-linear duration-300;
    }

    &.search-opened {
        .header-logo {
            @apply opacity-50 blur-[8px];
        }

        .btn-menu-wrap,
        .open-search {
            @apply hidden;
        }

        .header-search-form .header-search-form-inner {
            @apply opacity-100 visible z-998;
        }
    }

    .cart-menu-dropdown {
        &.active {
            @apply lg:!block;
        }
    }

    .dropdown-menu {
        &.active {
            @apply lg-down:block;
        }
    }

    input.input-white::placeholder {
        @apply opacity-100;
        @apply !text-primary-white;
    }

    input.input-white {
        @apply !text-primary-white;
    }

    input.input-primary-text::-webkit-input-placeholder {
        @apply !text-primary-white;
    }

    input.input-primary-text:-moz-placeholder {
        @apply !text-primary-white;
    }

    input.input-primary-text::-moz-placeholder {
        @apply !text-primary-white;
    }

    input.input-primary-text:-ms-input-placeholder {
        @apply !text-primary-white;
    }
}

.sticky-footer-bar {
    li a {
        &.active {
            @apply bg-primary-gold dark:bg-secondary-light-blue;

            svg {
                path.stroke-primary-gold {
                    @apply stroke-primary-white;
                }
            }

            span {
                @apply text-primary-white;

                &.cart-number {
                    @apply bg-primary-white text-primary-gold dark:text-secondary-light-blue;
                }
            }
        }
    }
}

.dark-only {
    &.i-d-o.dark\:bg-primary-blue {
        @apply bg-primary-blue;
    }

    &.i-d-o.dark\:border-secondary-light-blue {
        @apply border-secondary-light-blue;
    }

    .i-d-o.dark\:border-secondary-light-blue {
        @apply border-secondary-light-blue;
    }

    .i-d-o.dark\:lg\:before\:bg-secondary-light-blue:before {
        @screen lg {
            @apply bg-secondary-light-blue;
        }
    }

    .i-d-o.dark\:border-secondary-light-blue\[.50\] {
        @apply border-secondary-light-blue/[.50];
    }

    .i-d-o.dark\:lg\:border-secondary-light-blue {
        @screen lg {
            @apply border-secondary-light-blue;
        }
    }

    .i-d-o.dark\:lg\:bg-primary-blue {
        @apply bg-primary-blue;
    }

    .i-d-o.dark\:hidden {
        @apply hidden;
    }

    .i-d-o.dark\:block {
        @apply block;
    }

    .i-d-o.dark\:stroke-primary-white {
        @apply stroke-primary-white;
    }

    .i-d-o.text-primary-blue {
        @apply text-primary-white;
    }

    .btn-menu-wrap .btn-menu:before,
    .btn-menu-wrap .btn-menu:after,
    .btn-menu-wrap .btn-menu span {
        @apply bg-primary-white;
    }

    .btn-menu-wrap.active .btn-menu:after,
    .btn-menu-wrap.active .btn-menu:before {
        @apply bg-primary-white;
    }
}

.blog-slider-wrap {
    .blog-slider-pager {
        button {
            &.glide__bullet--active {
                @apply bg-primary-gold border-primary-gold dark:bg-secondary-light-blue dark:border-secondary-light-blue;
            }
        }
    }
}