@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'components/fonts.css';
@import 'components/structure.css';
@import 'components/buttons.css';
@import 'components/typography.css';
@import 'components/forms.css';
@import 'components/header-footer.css';
@import 'components/age-gate.css';
@import 'components/home-page.css';
@import 'components/about-page.css';

@import 'components/additional.css';

@import 'tailwindcss/utilities';

.responsive-object {
    position: relative;
    margin-bottom: 34px;
    margin-top:34px;
}

div + .responsive-object {
    margin-top:0;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}
